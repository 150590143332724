const personalInfo = {
  homeTitle: "Hi, my name is Felix Chen. I build things for the web.",
  homeParagraph:
    "I am software engineer based in Atlanta, GA with experience in building simple websites to fullstack applications.",
};

const projects = [
  {
    title: "Train Together",
    projectDescription:
      "The TrainTogether application is a a full-stack web app aimed to improve one's physical well being through stretches and other modalities. The app allows a user to upload, explore, and save various exercise programs.",
    projectLink: "https://train-together.cyclic.app/",
    projectStatus: "Deployed",
    technologies: ["React", "Express", "MongoDB", "Redux", "Tailwind"],
    projectPic: "train-together.cyclic.app_ (2) 4",
  },
  {
    title: "Miss Vietnam Florida",
    projectDescription:
      "Collaborated with a developer to build a modern and responsive website using best practices for potential Miss Vietnam Florida pageant applicants. Miss Vietnam Florida was built using HTML and CSS.",
    projectLink: "https://missvietnamflorida.com/",
    projectStatus: "Deployed",
    technologies: ["HTML", "CSS"],
    projectPic: "missvietnamflorida.com_ (1) 3",
  },
  {
    title: "Portfolio Site",
    projectDescription:
      "My portfolio site used to display information about me and my projects.",
    projectLink: "https://www.felixchen.co/",
    projectStatus: "Deployed",
    technologies: ["React", "HTML", "Tailwind"],
    projectPic: "profile-preview-2",
  },
];

export { personalInfo, projects };
